// std
import { useCallback, useEffect, useMemo, useState } from 'react';

// 3p
import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import axios from 'axios';

// app
import { EPOCH_PAYMENT_INFO, PaymentGatewayEnum } from 'config/Enum';
import { DEFAULT_GATEWAY } from 'config/Config';

import { IPayInTipForm, IPayInResponse, IModal } from 'interfaces';
import { useDebounce, useAuth, useAnalytics } from 'hooks';
import styles from 'styles';

import { useCreatePayInTip, useGetAllPayInSource } from 'api/transfer';

import { ContentPaymentSuccess } from './ContentPaymentSuccess';
import { ContentError } from './ContentError';
import { AlertInfo } from '../alert';
import RadioCardsImage from '../radio/RadioCardsImage';
import { ModalLayout } from 'components/common';

interface TipBuyModalProps {
  open: boolean;
  username: string;
  setOpen: React.Dispatch<React.SetStateAction<IModal<IPayInTipForm>>>;
  onSuccess?: (data?: IPayInResponse) => void;
  onError?: (error: any) => void;
  onClose: () => void;
}

export function TipBuyModal(props: TipBuyModalProps): JSX.Element {
  const { open, username, onSuccess, onError, onClose } = props;

  const [paymentInResponse, setPaymentInResponse] = useState<IPayInResponse>();

  const { user } = useAuth();
  const { createPayInMutation } = useCreatePayInTip();

  const {
    mutateAsync,
    isLoading: isLoadingCreatePayInUrl,
    isError: isErrorCreatePayInUrl,
  } = createPayInMutation;

  const defaultValues = useMemo(
    () => ({
      username: username,
      gateway: DEFAULT_GATEWAY,
    }),
    [username]
  );

  const { register, control, handleSubmit, reset, watch, setValue } =
    useForm<IPayInTipForm>({
      defaultValues: defaultValues,
    });

  const gateway = watch('gateway');
  const isPaymentGatewayEpoch = gateway === PaymentGatewayEnum.EPOCH;

  const amountValue = watch('amount');
  const amountValueDeferred = useDebounce<number>(amountValue);

  const queryParams = useMemo(() => {
    if (!amountValueDeferred) return undefined;
    return { amount: amountValueDeferred };
  }, [amountValueDeferred]);

  const { getAllPayInSourceQuery } = useGetAllPayInSource(queryParams);
  const { data: dataGetAllPayInSource } = getAllPayInSourceQuery;

  useEffect(() => {
    setValue('gateway', DEFAULT_GATEWAY);
  }, [dataGetAllPayInSource, setValue]);

  const onSubmit: SubmitHandler<IPayInTipForm> = async (data) => {
    try {
      const response = await mutateAsync(data);
      setPaymentInResponse(response);
    } catch (error) {
      if (axios.isAxiosError(error) && onError) {
        onError(error);
      } else {
        console.error(error);
      }
    }
  };

  const { trackEvent } = useAnalytics();

  const handleOnPaymentSuccess = useCallback(() => {
    trackEvent('purchase', {
      currency: 'EUR',
      value: amountValue,
      items: [
        {
          item_name: 'TIP',
        },
      ],
    });

    if (onSuccess) onSuccess();
  }, [amountValue, trackEvent, onSuccess]);

  const handleOnClickPaymentLink = useCallback(() => {
    // Save paymenet tracking data in local storage
    localStorage.setItem(
      'payment_data',
      JSON.stringify({
        currency: 'EUR',
        value: amountValue,
        items: [
          {
            item_name: 'TIP',
          },
        ],
      })
    );

    if (onSuccess) onSuccess();
  }, [amountValue, onSuccess]);

  // Set Default data and clean
  useEffect(() => {
    if (!open) {
      setPaymentInResponse(undefined);
    }
    reset(defaultValues);
  }, [defaultValues, open, reset]);

  const renderData = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.modal.closeContainer}>
        <button type="button" className={styles.modal.closeButton} onClick={onClose}>
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="sm:flex sm:items-start">
        <div className="mt-3 sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
          <Dialog.Title as="h3" className={styles.modal.title}>
            Totale: € {amountValue ? amountValue : 0}
          </Dialog.Title>

          <div className="mt-6 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-3">
            <div className="sm:col-span-3">
              {user ? (
                <>
                  <label className={styles.input.label}>Username</label>
                  <div className={styles.input.containerRelative}>
                    <input
                      disabled
                      type="text"
                      className={styles.input.base + ' ' + styles.input.disabled}
                      defaultValue={user.username}
                    />
                  </div>
                </>
              ) : (
                <>
                  <label htmlFor="email" className={styles.input.label}>
                    Indirizzo e-mail
                  </label>
                  <div className={styles.input.containerRelative}>
                    <input
                      id="email"
                      type="email"
                      className={styles.input.base}
                      {...register('email', { required: true })}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="amount" className={styles.input.label}>
                Importo della TIP
              </label>
              <div className={styles.input.containerRelative}>
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">€</span>
                </div>
                <input
                  id="amount"
                  className=" sm:text-lg focus:ring-pink-500 focus:border-pink-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                  placeholder="0.00"
                  aria-describedby="price-currency"
                  type="number"
                  min="3"
                  max="400"
                  step="any"
                  {...register('amount', {
                    required: true,
                    valueAsNumber: true,
                    max: 400,
                    min: 3,
                  })}
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm" id="price-currency">
                    EUR
                  </span>
                </div>
              </div>
              <p className={styles.input.descr}>Importo minimo €3.00</p>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="email" className={styles.input.label}>
                Messaggio (facoltativo)
              </label>
              <div className={styles.input.containerRelative}>
                <input
                  id="message"
                  type="text"
                  className={styles.input.base}
                  {...register('message', { required: false, maxLength: 70 })}
                />
              </div>
              <p className={styles.input.descr}>Massimo 70 caratteri</p>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="gateway" className={styles.input.label}>
                Scegli la modalità di pagamento
              </label>
              <Controller
                name="gateway"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <RadioCardsImage
                    onChange={onChange}
                    value={value}
                    options={dataGetAllPayInSource}
                  />
                )}
              />
            </div>
            {isPaymentGatewayEpoch && (
              <div className="sm:col-span-3">
                <AlertInfo message={EPOCH_PAYMENT_INFO} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.modal.footer}>
        <button type="submit" className={styles.modal.button1}>
          Invia
        </button>
        <button type="button" className={styles.modal.button2} onClick={onClose}>
          Annulla
        </button>
      </div>
    </form>
  );

  return (
    <ModalLayout open={open} onClose={onClose} isLoading={isLoadingCreatePayInUrl}>
      {paymentInResponse ? (
        <ContentPaymentSuccess
          payment={paymentInResponse}
          onSuccess={handleOnPaymentSuccess}
          onClickLink={handleOnClickPaymentLink}
        />
      ) : username ? (
        renderData()
      ) : isErrorCreatePayInUrl ? (
        <ContentError />
      ) : (
        <div />
      )}
    </ModalLayout>
  );
}
